import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export interface SearchLayoutProps {
  className?: string;
  children: ReactNode;
}

export const SearchLayout: FC<SearchLayoutProps> = ({ className, children }) => {
  return (
    <div className={clsx('page-layout flex min-h-screen flex-col bg-gray-50', className)} role="presentation">
      <main className="flex-auto w-full" aria-label="Search content">
        {children}
      </main>
    </div>
  );
};
