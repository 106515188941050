import type { MetaFunction } from '@vercel/remix';
import type { Storefront } from 'storefront-client';
import { getProxySrc } from '../../libs/util/img-proxy';
import { getCommonMeta, mergeMeta } from './meta';

export const getRootMeta: MetaFunction = ({ data }) => {
  const storefrontSettings = (data as { storefrontSettings: Storefront })?.storefrontSettings;

  if (!storefrontSettings) return [];

  const title: string = storefrontSettings?.name;
  const description = title;
  const ogTitle = title;
  const ogDescription = description;
  const ogImage = getProxySrc(storefrontSettings?.logo?.url);
  const ogImageAlt = ogImage ? `${ogTitle} logo` : undefined;

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:title', content: ogTitle },
    { property: 'og:description', content: ogDescription },
    { property: 'og:image', content: ogImage },
    { property: 'og:image:alt', content: ogImageAlt },
  ];
};

export const getMergedRootMeta = mergeMeta(getCommonMeta, getRootMeta);
