import type { Storefront } from 'storefront-client';
import { hexToRgb } from './colors';
import type { StyleColor } from './medusa/types';

export const generateCSSVariablesInnerHTML = ({
  primary_theme_colors,
  secondary_theme_colors,
  accent_theme_colors,
  highlight_theme_colors,
}: Storefront) => {
  let cssVarsString = '';
  if (primary_theme_colors && accent_theme_colors && highlight_theme_colors && secondary_theme_colors) {
    cssVarsString = ['primary', 'secondary', 'accent', 'highlight'].reduce((cssVarsStringAcc, colorTypeCurr) => {
      const colorObjects: Record<string, StyleColor> = {
        primary: primary_theme_colors,
        secondary: secondary_theme_colors,
        accent: accent_theme_colors,
        highlight: highlight_theme_colors,
      };

      const colorVariables = Object.entries(colorObjects[colorTypeCurr]).reduce(
        (acc, [colorKey, colorValue]) => `${acc}--color-${colorTypeCurr}-${colorKey}: ${hexToRgb(colorValue)};`,
        '',
      );

      return `${cssVarsStringAcc}${colorVariables}`;
    }, '');
  }

  return `:root {${cssVarsString}}`;
};
