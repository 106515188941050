import { useCart } from '@storefront/ui-components/hooks/useCart';
import { useLogin } from '@storefront/ui-components/hooks/useLogin';
import { Modal } from '@storefront/ui-components/shared/modals';
import { LoginForm } from './LoginForm';

export interface LoginModalProps {
  name?: 'Login' | 'Checkout Login';
}
export const LoginModal = ({ name = 'Login' }: LoginModalProps) => {
  const { cart } = useCart();
  const { login, toggleLoginModal } = useLogin();

  return (
    <Modal name={name} isOpen={!!login.open} onClose={() => toggleLoginModal(false)}>
      <h2 className="text-lg font-bold text-gray-900 mb-4">Log in to your account</h2>
      <LoginForm
        redirect={login.redirect}
        defaultValues={{ email: cart?.email ?? '' }}
        onSuccess={() => toggleLoginModal(false)}
      />
    </Modal>
  );
};
