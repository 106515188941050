import { Header as HeaderComponent } from '@storefront/ui-components/header/Header';
import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { useStorefront } from '@storefront/ui-components/hooks/useStorefront';
import useUserLocation from '@storefront/ui-components/hooks/useUserLocation';
import { useStorefrontSettings } from '~/utils/use-storefront-settings';

export const Header = () => {
  const location = useUserLocation();
  const { customer } = useCustomer();
  const store = useStorefrontSettings();
  const { actions } = useStorefront();

  if (!store) return <>Loading...</>;

  return (
    <header id="mkt-header" className="sticky top-0 z-40" key={location.city}>
      <nav aria-label="Top">
        <div className="bg-white">
          <HeaderComponent
            customer={customer}
            location={location}
            toggleLocation={actions.toggleLocationModal}
            toggleSearch={actions.toggleSearchDrawer}
          />
        </div>
      </nav>
    </header>
  );
};
