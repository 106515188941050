import { Button } from '@storefront/ui-components/shared/buttons';
import { Form, type FormProps } from '@storefront/ui-components/shared/forms/Form';
import { FormError } from '@storefront/ui-components/shared/forms/FormError';
import { FieldText } from '@storefront/ui-components/shared/forms/fields/FieldText';
import { type FC, useState } from 'react';
import * as Yup from 'yup';
import { useSendEvent } from '../../../libs/util/analytics/useAnalytics';

export interface UnsupportedPostalFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('This field is required'),
});

export interface UnsupportedPostalFormProps extends Partial<FormProps<UnsupportedPostalFormValues, undefined>> {
  onSuccess?: () => void;
  defaultValues?: Partial<UnsupportedPostalFormValues>;
}

const UnsupportedPostalCodeForm: FC<UnsupportedPostalFormProps> = ({ onSuccess, ...props }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const subscribeUser = useSendEvent('subscribe');

  const handleSubmit = (data: UnsupportedPostalFormValues) => {
    setIsSubscribed(true);
    subscribeUser({ email: data.email });
    onSuccess?.();
  };

  return (
    <Form<UnsupportedPostalFormValues>
      id="unsupportedPostalCodeForm"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...props}
    >
      {!isSubscribed ? (
        <>
          <div className="flex flex-col align-middle items-center">
            <p className="py-7 text-center hidden md:block">
              We currently don't have any products available in your area. Sign up to be <br /> the first to know when
              we have products near you!
            </p>

            <p className="py-7 text-center block sm:hidden">
              Aww! We don't offer this service in your area yet!{' '}
              <span className="whitespace-nowrap">Sign up to get notified!</span>
            </p>
          </div>

          <div className="h-full sm:h-auto flex flex-col flex-grow justify-between sm:items-start sm:flex-row gap-2 sm:mt-5">
            <FieldText className="flex-auto" name="email" placeholder="Enter your email" />

            <div className="sm:flex sm:justify-end">
              <Button type="submit" variant="primary" className="w-full sm:w-auto min-w-40">
                Sign Up
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center align-middle">
          <p className="font-bold">Thank you for subscribing!</p>
          <p>We will notify you as soon as our service is available in your area.</p>
        </div>
      )}
      <FormError />
    </Form>
  );
};

export default UnsupportedPostalCodeForm;
