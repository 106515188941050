import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  type ShouldRevalidateFunction,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import RefinerComponent from '@storefront/util/analytics/refiner/RefinerContent';
import type { LinksFunction, LoaderFunction, MetaFunction } from '@vercel/remix';
import { useRef } from 'react';
import { SetupClarity } from '~/components/layout/SetupClarity';
import { Page } from './components/layout/page/Page';
import { RootProviders } from './providers/root-providers';
import { getMergedRootMeta } from './utils/root';
import { type RootLoader, getRootLoader } from './utils/server/root.server';

import '@smastrom/react-rating/style.css';
import '~/styles/global.css';
import { generateCSSVariablesInnerHTML } from '@storefront/util/css';
import { ClientOnly } from 'remix-utils/client-only';
import { SetupAnalytics } from './components/layout/SetupAnalytics';

export const loader: LoaderFunction = getRootLoader;

export const meta: MetaFunction<typeof loader> = getMergedRootMeta;

export const links: LinksFunction = () => {
  const manifestLink = { rel: 'manifest', type: 'application/json', href: '/manifest.json' };

  const links = [manifestLink];

  return links;
};

function App() {
  const headRef = useRef<HTMLHeadElement>(null);
  const loaderData = useLoaderData<RootLoader>();
  const { env, cart: _cart, fontLinks, storefrontSettings } = loaderData;

  const cssVariables = storefrontSettings ? generateCSSVariablesInnerHTML(storefrontSettings) : '';

  return (
    <RootProviders>
      <html lang="en" className="min-h-screen">
        <head ref={headRef}>
          <meta charSet="utf-8" />
          <Meta />
          {fontLinks.map((fontLink: string) => (
            <link key={fontLink} rel="stylesheet" href={fontLink} />
          ))}
          <Links />
          <SetupAnalytics />
          <SetupClarity />
          {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
          <style dangerouslySetInnerHTML={{ __html: cssVariables }} />
        </head>
        <body className="min-h-screen">
          <Page>
            <Outlet />
          </Page>
          <script
            // biome-ignore lint/security/noDangerouslySetInnerHtml: ignore
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(env)}`,
            }}
          />
          <RefinerComponent />
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    </RootProviders>
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  actionResult,
  currentParams,
  currentUrl,
  defaultShouldRevalidate,
  formAction,
  formData,
  formEncType,
  formMethod,
  nextParams,
  nextUrl,
}) => {
  if (!formMethod || formMethod === 'GET') return false;

  return defaultShouldRevalidate;
};

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  console.error('error boundary error', error);

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Scripts />
      </body>
    </html>
  );
}
