import { useLocation } from '@remix-run/react';
import { LoginModal } from '@storefront/ui-components/auth/login/LoginModal';
import { SignUpModal } from '@storefront/ui-components/auth/signup/SignupModal';
import type { FC, ReactNode } from 'react';
import { PostalCodeModal } from '~/components/postal/PostalCodeModal';
import { CheckoutLayout } from './CheckoutLayout';
import { DefaultLayout } from './DefaultLayout';
import { SearchLayout } from './SearchLayout';

export interface PageProps {
  className?: string;
  children: ReactNode;
}

const PageLayoutMap = {
  checkout: CheckoutLayout,
  search: SearchLayout,
} as const;

export const Page: FC<PageProps> = (props) => {
  const location = useLocation();
  const paths = Object.keys(PageLayoutMap);
  const foundPath = paths.find((path) => location.pathname.includes(path)) as keyof typeof PageLayoutMap;
  const Layout = foundPath ? PageLayoutMap[foundPath] : DefaultLayout;
  return (
    <>
      <LoginModal />
      <SignUpModal />
      <PostalCodeModal />
      <Layout {...props} />
    </>
  );
};
