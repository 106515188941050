import { useFetcher } from '@remix-run/react';
import { withYup } from '@remix-validated-form/with-yup';
import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { useLogin } from '@storefront/ui-components/hooks/useLogin';
import { useSignUp } from '@storefront/ui-components/hooks/useSignup';
import { ButtonLink, SubmitButton } from '@storefront/ui-components/shared/buttons';
import { Form, type FormProps } from '@storefront/ui-components/shared/forms/Form';
import { FormError } from '@storefront/ui-components/shared/forms/FormError';
import { FieldGroup } from '@storefront/ui-components/shared/forms/fields/FieldGroup';
import { FieldPassword } from '@storefront/ui-components/shared/forms/fields/FieldPassword';
import { FieldText } from '@storefront/ui-components/shared/forms/fields/FieldText';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import { type FC, useEffect } from 'react';
import * as Yup from 'yup';

export interface SignUpFormValues {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

export interface SignupFormProps extends Partial<FormProps<SignUpFormValues, 'signUp'>> {
  onSuccess?: () => void;
  defaultValues?: Partial<SignUpFormValues>;
}

export const signUpFormValidator = withYup(
  Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
  }),
);

export const SignUpForm: FC<SignupFormProps> = ({ onSuccess, ...props }) => {
  const fetcher = useFetcher<EmptyUseFetcher>();
  const { customer } = useCustomer();
  const sendSignUpEvent = useSendEvent('sign_up');
  const { toggleLoginModal } = useLogin();
  const { toggleSignUpModal } = useSignUp();
  const isLoggedIn = customer?.id;
  const isSubmitting = fetcher.state === 'submitting' || fetcher.state === 'loading';

  const defaultValues = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    ...props.defaultValues,
  };

  const handleSignInClick = () => {
    toggleSignUpModal(false);
    toggleLoginModal(true);
  };

  useEffect(() => {
    if (!isSubmitting && isLoggedIn && onSuccess) {
      onSuccess();
      sendSignUpEvent({ email: defaultValues.email, method: 'password' });
    }
  }, [isSubmitting, isLoggedIn]);

  return (
    <Form<SignUpFormValues, 'signUp'>
      id="signUpForm"
      method="post"
      action="/api/auth"
      subaction="signUp"
      fetcher={fetcher}
      validator={signUpFormValidator}
      {...props}
      defaultValues={defaultValues}
    >
      <FormError className="mt-0" />

      <FieldGroup className="mt-0">
        <FieldText
          name="first_name"
          label="First name"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
        <FieldText
          name="last_name"
          label="Last name"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
        <FieldText
          type="email"
          name="email"
          label="Email address"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
        <FieldPassword
          name="password"
          label="Password"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
      </FieldGroup>
      <FieldGroup className="mt-0 items-center">
        <div className="col-span-12 flex items-center justify-between">
          Already have an account?
          <ButtonLink size="sm" onClick={handleSignInClick}>
            Sign in.
          </ButtonLink>
        </div>
      </FieldGroup>
      <SubmitButton className="block w-full">{isSubmitting ? 'Creating...' : 'Create Account'}</SubmitButton>
    </Form>
  );
};
