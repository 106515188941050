import { IconButton } from '@storefront/ui-components/shared/buttons';
import { CloseIcon } from '@storefront/ui-components/shared/icons';
import { Modal, type ModalProps } from '@storefront/ui-components/shared/modals';

export const PostalCodeModalContainer = ({ children, title, ...props }: ModalProps) => {
  return (
    <Modal {...props}>
      <div className="fixed top-2 right-2 pt-2 pr-2">
        <IconButton
          className="w-6 h-6"
          icon={() => <CloseIcon />}
          type="button"
          aria-label="Close dialog"
          onClick={props.onClose}
        />
      </div>
      <h5 className="font-bold text-gray-900 text-center mb-5 text-xl">{title}</h5>
      {children}
    </Modal>
  );
};
