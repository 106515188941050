import { useFetcher } from '@remix-run/react';
import useUserLocation from '@storefront/ui-components/hooks/useUserLocation';
import { Button } from '@storefront/ui-components/shared/buttons';
import { Form, type FormProps } from '@storefront/ui-components/shared/forms/Form';
import { FormError } from '@storefront/ui-components/shared/forms/FormError';
import { FieldText } from '@storefront/ui-components/shared/forms/fields/FieldText';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import { type FC, useEffect } from 'react';
import { PostalCodeActions, type VerifyPostalCodeResponse, postalCodeValidator } from '~/routes/api.postal-codes';

export interface PostalFormValues {
  postalCode: string;
}

export interface PostalFormProps extends Partial<FormProps<PostalFormValues, PostalCodeActions.verifyPostalCode>> {
  onClose?: () => void;
  onUnsupportedPostalCode?: (postalCode: string) => void;
  defaultValues?: Partial<PostalFormValues>;
}

const PostalCodeForm: FC<PostalFormProps> = ({ onClose, onUnsupportedPostalCode, ...props }) => {
  const fetcher = useFetcher<VerifyPostalCodeResponse>();
  const isValidPostalCode = fetcher.data?.valid;
  const submittedPostalCode = fetcher.data?.postalCode;
  const sendPostalCodeEnteredEvent = useSendEvent('postal_code_entered');
  const location = useUserLocation();

  const defaultValues = {
    postalCode: location?.postalCode ?? '',
  };

  useEffect(() => {
    if (submittedPostalCode && typeof isValidPostalCode === 'boolean')
      sendPostalCodeEnteredEvent({ postalCode: submittedPostalCode, supported: isValidPostalCode });
    if (isValidPostalCode === true) onClose?.();
    if (isValidPostalCode === false && submittedPostalCode) onUnsupportedPostalCode?.(submittedPostalCode);
  }, [isValidPostalCode, submittedPostalCode]);

  return (
    <Form<PostalFormValues, PostalCodeActions.verifyPostalCode>
      id="postalCodeForm"
      method="post"
      action="/api/postal-codes"
      subaction={PostalCodeActions.verifyPostalCode}
      fetcher={fetcher}
      validator={postalCodeValidator}
      defaultValues={defaultValues}
      {...props}
    >
      <label htmlFor="postalCode" className="block text-center">
        Item availability and shipping options <br /> will change based on location
      </label>
      <div className="flex flex-col sm:flex-row sm:items-start gap-2 mt-5">
        <div className="flex-auto">
          <FieldText name="postalCode" placeholder="Postal Code" />
          <FormError className="sm:hidden" />
        </div>

        <Button type="submit" variant="primary" className="min-w-40">
          Update
        </Button>
      </div>
      <FormError className="hidden sm:block" />
    </Form>
  );
};

export default PostalCodeForm;
