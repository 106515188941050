import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { isBrowser } from '@storefront/util/browser';
import { useEffect, useRef } from 'react';
import { identifySegmentUser } from '../helpers';

export const useSegmentIdentify = () => {
  const { customer } = useCustomer();
  const alreadyIdentified = useRef(false);

  useEffect(() => {
    if (!customer || !isBrowser() || alreadyIdentified.current) return;
    void identifySegmentUser(customer);
    alreadyIdentified.current = true;
  }, [customer]);
};
