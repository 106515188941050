import { useFetcher, useNavigate } from '@remix-run/react';
import { withYup } from '@remix-validated-form/with-yup';
import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { useLogin } from '@storefront/ui-components/hooks/useLogin';
import { ButtonLink, SubmitButton } from '@storefront/ui-components/shared/buttons';
import { Form, type FormProps } from '@storefront/ui-components/shared/forms/Form';
import { FormError } from '@storefront/ui-components/shared/forms/FormError';
import { FieldCheckbox } from '@storefront/ui-components/shared/forms/fields/FieldCheckbox';
import { FieldGroup } from '@storefront/ui-components/shared/forms/fields/FieldGroup';
import { FieldPassword } from '@storefront/ui-components/shared/forms/fields/FieldPassword';
import { FieldText } from '@storefront/ui-components/shared/forms/fields/FieldText';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import { type FC, useEffect } from 'react';
import * as Yup from 'yup';

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormProps extends Partial<FormProps<LoginFormValues, 'login'>> {
  redirect?: string;
  onSuccess?: () => void;
  defaultValues?: Partial<LoginFormValues>;
}

export const loginFormValidator = withYup(
  Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
    rememberMe: Yup.string().optional(),
  }),
);

export const LoginForm: FC<LoginFormProps> = ({ redirect, onSuccess, ...props }) => {
  const fetcher = useFetcher<EmptyUseFetcher>();
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const { toggleLoginModal } = useLogin();
  const sendLoginEvent = useSendEvent('login');
  const isLoggedIn = customer?.id;
  const isSubmitting = fetcher.state === 'submitting' || fetcher.state === 'loading';

  const defaultValues = {
    email: '',
    password: '',
    rememberMe: false,
    ...props.defaultValues,
  };

  useEffect(() => {
    if (!isSubmitting && isLoggedIn && onSuccess) {
      onSuccess();
      sendLoginEvent({ method: 'password', customer });
    }
  }, [isSubmitting, isLoggedIn]);

  const handleForgotPasswordClick = () => {
    toggleLoginModal(false);
    navigate('/forgot-password');
  };

  return (
    <Form<LoginFormValues, 'login'>
      id="loginForm"
      method="post"
      action="/api/auth"
      subaction="login"
      fetcher={fetcher}
      validator={loginFormValidator}
      {...props}
      defaultValues={defaultValues}
    >
      <input type="hidden" name="redirect" value={redirect} />

      <FormError className="mt-0" />

      <FieldGroup className="mt-0">
        <FieldText
          type="email"
          name="email"
          label="Email address"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
        <FieldPassword
          name="password"
          label="Password"
          fieldOptions={{
            validationBehavior: {
              initial: 'onSubmit',
              whenTouched: 'onSubmit',
              whenSubmitted: 'onChange',
            },
          }}
        />
      </FieldGroup>

      <FieldGroup className="mt-0 items-center">
        <div className="col-span-12 flex items-center justify-between">
          <FieldCheckbox name="rememberMe" label="Remember me" />
          <ButtonLink size="sm" onClick={handleForgotPasswordClick}>
            Forgot your password?
          </ButtonLink>
        </div>
      </FieldGroup>

      <SubmitButton className="block w-full">{isSubmitting ? 'Logging in...' : 'Log in'}</SubmitButton>
    </Form>
  );
};
